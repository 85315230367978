import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import Vue from "vue";
import i18n from "../utils/i18n";
// ido私募合约
export default {
//
  async idoLimits(currentAddress, ContractAddress, _decimals, selected, Leave) {
    try {
      //   let position = "";
      console.log(Leave)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.idoLimits(selected, Leave)

      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },

  //
  async isOpen(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract._isOpen()


      return {code: "0", data: balance};
    } catch (error) {
      //  console.log(error);
    }
  },
  //
  async participated(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.participated(currentAddress)


      return {code: "0", data: balance};
    } catch (error) {
      //  console.log(error);
    }
  },
  //
  async getpledgeper(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getpledgeper(currentAddress)
      console.log(balance)
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      //  console.log(error);
    }
  },
  //
  async vestingSchedules(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.vestingSchedules(currentAddress)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      newData.haveAmount = big(newData.haveAmount)
        .div(10 ** 18)
        .toFixed();
      newData.releasedAmount = big(newData.releasedAmount)
        .div(10 ** 18)
        .toFixed();
      newData.totalAmount = big(newData.totalAmount)
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: newData};
    } catch (error) {
      //  console.log(error);
    }
  },
  async maxIDOAmount(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.maxIDOAmount()
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();

      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },


  // ido
  async ido(
    addresscode,
    ContractAddress,
    _decimals,
    selected,
    value
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)

      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)
      const amountApproved = big(value)
        .times(10 ** 18)
        .toFixed();

      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.ido(addresscode, amountApproved, selected); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .ido(addresscode, amountApproved, selected)
        .send({
          from: addresscode,
          gasLimit: hexValue,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 2),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }
      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },

  // pido
  async pido(
    addresscode,
    ContractAddress,
    _decimals,
    selected,
    Leave,
    value
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)

      //    console.log(Factory.abi)
      const amountApproved = big(value)
        .times(10 ** 18)
        .toFixed();
      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.pido(addresscode, amountApproved); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .pido(addresscode, amountApproved)
        .send({
          from: addresscode,
          gasLimit: hexValue,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 2),
        });

      return {code: "0"};
    } catch (error) {
      //    console.log(error);
    }
  },
  // release
  async release(
    addresscode,
    ContractAddress,
    _decimals,
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/idoapi2.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.release(); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .release()
        .send({
          from: addresscode,
          gasLimit: hexValue,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = i18n.t("ido.message");
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };
    }
  },
};

import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
// tb合约pg合约方法
export default {
//
  async _falconAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falocnn.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract._falconAddress(currentAddress)


      return {code: "0", data: balance};
    } catch (error) {
      //  console.log(error);
    }
  },
  //
  async isOpen(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falocnn.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.isOpen()


      return {code: "0", data: balance};
    } catch (error) {
      //  console.log(error);
    }
  },
  async _flashAddress(currentAddress, ContractAddress, _decimals) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falocnn.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract._flashAddress(currentAddress)

      return {code: "0", data: balance};
    } catch (error) {
      //  console.log(error);
    }
  },

  // 兑换
  async falcon(
    addresscode,
    ContractAddress,
    returndatadata,
    _decimals
  ) {
    try {
      //   let position = "";
      //  console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/Falocnn.json");
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);
      // 创建合约对象
      //    console.log(Factory.abi)


      //   console.log(amountApproved)
      const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);

      // console.log(tokebContract)
      const approveFunction = tokebContract.methods.falcon(returndatadata); // 替换为您要授权的代币数量
      //  console.log(approveFunction)
      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      //  预估
      const hexValue = await approveFunction.estimateGas({
        from: addresscode,
        to: ContractAddress,
        data: approveFunction.encodeABI(),
      });
      //   console.log(hexValue);

      await tokebContract.methods
        .falcon(returndatadata)
        .send({
          from: addresscode,
          gasLimit: hexValue,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      //    console.log(error);
    }
  },

};

const ko = {
  network: {
    tips: `선택한 네트워크는 현재 지원되지 않습니다!
    Matic(Polygon) 네트워크를 선택하세요!'`,
    ev: "MetaMask, Trust Wallet, imToken, TokenPocket과 같은 지갑 소프트웨어가 설치되어 있는지 확인하세요.",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "최대",
    bolck: "Dai 스테이블코인",
    law2: `<center><h1>정보 동의서</h1></center>
    <br>
    <p>저는 광동성 Saier Biotechnology Co., Ltd.(이하 'Saier 그룹')의 세포 중재 프로젝트에 자발적으로 참여합니다. 참여하기 전에, 프로젝트의 배경, 목표 및 관련 권리를 포함한 아래 내용을 주의 깊게 읽고 충분히 이해했습니다.</p>
    <br>
    <h2>1. 세포 중재의 목적:</h2>
    <p>이 세포 중재 프로젝트는 세포 중재를 통해 건강 상태를 개선하고, 중재 전후의 변화를 관찰하는 것을 목표로 합니다. 과학적 접근 방식을 통해 제 상태가 현저히 회복되고 개선되기를 기대합니다.</p>
    <br>
    <h2>2. 의료 발전 촉진:</h2>
    <p>이 프로젝트에서, 저와 Saier 그룹의 치료팀은 세포 중재 후 회복 결과를 공동으로 소유합니다. 양측은 이 결과를 공유하여 관련 의료 및 과학 분야의 발전을 촉진합니다.</p>
    <br>
    <h2>3. 정보 동의:</h2>
    <p>Saier 그룹은 세포 중재 과정에서 생성된 모든 자료를 소유합니다. 이 자료에는 회복 기록, 상태 개선 기록, 얼굴 특징 및 기타 장기 개선 데이터가 포함되며, 이에 국한되지 않습니다. Saier 그룹이 이러한 자료를 보존, 해석, 활용할 권리가 있으며, 적절한 경우 관련 연구 결과를 발표하거나 과학적 목적으로 공유하는 것에 동의합니다.</p>
    <br>
    <h2>4. 건강 위험:</h2>
    <p>이 프로젝트 참여는 전적으로 자발적입니다. Saier 그룹은 세포 중재를 위해 과학적 방법을 채택하지만, 개인 차이에 따라 특정 위험 및 불확실성이 존재할 수 있습니다. Saier 그룹은 프로젝트 시작 전에 이러한 위험에 대해 자세히 설명하고, 제 안전과 권리를 보장하기 위해 최선을 다할 것입니다.</p>
    <p>위의 상황에 대해 정보를 받고, 관련 잠재적 위험을 이해한 후, 이 과정과 관련된 계약 분쟁을 제기하지 않기로 동의합니다. 저는 이 내용을 명확히 이해하고 수락하며, Saier 그룹이 관련 권리를 소유하는 것에 동의합니다.</p>`,
    law: `<h3><strong style="color:yellow">이 DAPP은 특정 국가 및 지역에서 법적 보호를 받지 않으므로 주의하여 사용하십시오!</strong></h3>
    <br/>
    <p><strong>사용자 여러분께:</strong></p>
    <br/>
    <p>이 DAPP에 대한 관심과 지원에 감사드립니다. 법적 권리를 보호하고 관련 법적 위험을 이해하도록 돕기 위해 아래 중요한 공지를 발표합니다:</p><br/>
    <p>이 DAPP은 암호화폐 투자 및 거래를 포함하며, 특정 국가 및 지역의 법률을 위반할 수 있습니다. 현지 규제 당국의 법률, 규정 및 정책에 따라, 이 DAPP은 일부 국가 및 지역에서 합법적으로 운영될 수 없으므로 다음과 같이 선언합니다:</p>
    <br/>
    <h3><strong>1. 서비스 범위 제한:</strong></h3><br/>
    <p style="color:yellow">이 DAPP은 다음 국가 및 지역에서 암호화폐 투자 및 거래 서비스를 제공하지 않으며, 기술 지원 또는 애프터 서비스를 제공하지 않습니다:</p><br/>
    <p style="color:yellow">중국(중국 본토, 홍콩 특별행정구, 마카오 특별행정구, 대만 포함), 인도, 알제리, 방글라데시, 모로코, 이집트, 네팔, 파키스탄, 카타르, 이라크, 튀니지, 아프가니스탄, 볼리비아.</p>
    <br/> <strong>위 언급된 국가 및 지역의 사용자께서는 즉시 이 DAPP 사용을 중단하고 법적 위험을 피하시기를 권장합니다.</strong>
    <br/><br/>
    <h3><strong>2. 법적 및 투자 위험 경고:</strong></h3><br/>
    <p>위 언급된 국가 및 지역에서 이 DAPP을 암호화폐 투자 및 거래에 사용하는 것은 심각한 법적 결과를 초래할 수 있습니다. 우리 팀은 그러한 행동에서 발생하는 법적 책임을 지지 않습니다. 또한, 암호화폐 시장 자체가 매우 변동성이 크고 예측할 수 없으며, 투자 활동에는 상당한 위험이 따릅니다. 이 DAPP을 사용하여 투자 및 거래를 계속할지 여부를 결정하기 전에 관련 위험을 충분히 이해하고 확인하십시오.</p>
    <br/>
    <h3><strong>3. 사용자 확인:</strong></h3><br/>
    <p>이 DAPP을 암호화폐 투자 및 거래에 계속 사용하는 경우, 본 공지의 내용을 충분히 읽고 이해했으며, 발생할 수 있는 법적 및 투자 위험을 인지하고 이를 수용할 의사가 있음을 인정한 것으로 간주됩니다.</p>
    <br/>
    <h3><strong>4. 운영 팀의 성명:</strong></h3><br/>
    <p>이 DAPP은 Bull Developer 팀에 의해 개발 및 운영됩니다. 우리는 항상 규정을 준수하며, 사용자에게 고품질 서비스를 제공하기 위해 노력해왔습니다. 그러나 일부 국가 및 지역의 특별한 법적 환경으로 인해, 이러한 지역에서 암호화폐 관련 서비스를 계속 제공할 수 없습니다. 이 DAPP을 사용하기 전에 각 국가 및 지역의 법률 및 규정을 이해하고 준수할 것을 강력히 권장합니다.</p>
    <br/>
    <p><strong>다시 한 번 여러분의 이해와 지원에 감사드립니다. 질문이나 추가 지원이 필요하면 공식 웹사이트를 통해 문의하시기 바랍니다.</strong></p>
    <br/>
    <p><strong>감사합니다!</strong></p>
    <br/>
    <p><strong>Bull Developer 팀</strong></p>`,
    protocol: `이 분산형 애플리케이션(DApp)은 불확실한 투자 위험을 포함합니다. 사용 전에, 위험 경고 및 개인 정보 보호 정책을 충분히 읽고 이해하십시오.`,
    more: "더보기",
    media: "관련 소셜 미디어",
    stake: "2023년 11월 6일 전 세계 개방. Telegram 및 Twitter를 팔로우하세요!",
    withdraw: "2023년 11월 6일 전 세계 개방. Telegram 및 Twitter를 팔로우하세요!",
    Reinvest: "2023년 11월 6일 전 세계 개방. Telegram 및 Twitter를 팔로우하세요!",
    Getreward: "2023년 11월 6일 전 세계 개방. Telegram 및 Twitter를 팔로우하세요!",
    linkwallet: "지갑 연결",
    notification: "Falcon 번 가속 공개 테스트가 성공적으로 종료되었으며, 이제 공식 계약 배포로 진행됩니다. 계속 지켜봐 주십시오!",
    notificationwith: "Bull Fund는 2024년 10월 25일부터 미처리된 인출 처리를 시작합니다! 처리는 주 1회 진행되므로 계속 지켜봐 주십시오!",
    flashloansOK: '시작 성공!',
    flashloansNo: '시작 실패!',
    withdrawinfo: "자산이 청산 중입니다. 청산이 완료될 때까지 기다려 주십시오!",
    flashloansOnce: '축하합니다! 1번 체험 기회를 얻었습니다!',
    flashloansError: '죄송합니다. 체험 기회가 소진되었습니다!',
    falocnerror: 'Falcon™ V2 테스트 자격이 부여되지 않았습니다!',
    falocninfo: 'Falcon™ V2는 V3 레벨로 승인된 플레이어에게 제공됩니다! 현재 공개 베타 중입니다!',
    falconv4: 'Falcon™V4 재정 거래 클러스터가 베타 테스트 중입니다(일일 28,000회 요청)!'
  },
  leavel1: {
    0: "공룡 알",
    1: "스피노사우루스",
    2: "스테고사우루스",
    3: "프테라노돈",
    4: "트리케라톱스",
    5: "브라키오사우루스",
    6: "벨로키랍토르",
    7: "티라노사우루스 렉스",
    8: "티라노사우루스 렉스",
    9: "티라노사우루스 렉스",
    10: "티라노사우루스 렉스",
  },
  leavel2: {
    0: "씨앗",
    1: "블랙커런트",
    2: "체리",
    3: "드래곤프루트",
    4: "헤이즐넛",
    5: "노니",
    6: "감",
    7: "트러플",
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  ido: {
    select: "커뮤니티 선택",
    flashmonkey: "플래시몽키 (FlashMonkey)",
    flashdragon: "플래시드래곤 (FlashDragon)",
    fashFalcon: "팔콘 (Falcon)",
    powermonger: "파워몽거 (PowerMonger)",
    fashfalcon2: "팔콘V2 (FalconV2)",
    other: "기타",
    Getmax: "최대 얻기",
    nowbuy: "지금 구독",
    mynumber: "내 팔콘",
    myrelease: "출시됨",
    waitrelease: "출시 대기 중",
    get: "출금",
    FalconF: "플래시팔콘(F)",
    FalconA: "플래시팔콘(A)",
    organization: "조직",
    endTimes: "1라운드 카운트다운",
    getmax: "최대 얻기",
    Authorization: "승인",
    Subscribe: "지금 구독",
    okSubscribe: "구독 완료",
    message: "노드 오류, 노드를 변경하세요.",
    end1: "1라운드 종료",
    needStart: "시작 카운트다운",
    getscusse: "회수 성공",
    geterror: "회수 실패",
  },
  top: {
    Account: "계정",
  },
  locked: {
    title: "잠긴 자산",
    genetic: "유전자 제품",
    direct: "직접 추천",
    Aggregation: "집계",
    Back: "뒤로가기",
  },
  stake: {
    Balance: "귀하의 잔액",
    Total: "총 스테이킹",
    Appoove: "승인",
    Stake: "스테이킹",
    Appooveing: "승인 중",
    AuthorSuccessful: "승인 성공",
    AuthorFailed: "승인 실패",
    Stakeing: "입금 중",
    StakeSuccessful: "입금 성공",
    StakeFailed: "입금 실패",
    StakeInfo: "다음보다 적을 수 없습니다:",
    Activeaccount: "계정 활성화",
    active: "활성화",
    auto: "자동",
    manual: "수동",
    info: "투자 계획을 선택하세요.",
    Demand: "요구 불입금",
    Fixed: "고정 예금",
    Wallet: "귀하의 지갑",
    Prompt: "최소 투자 금액은 100 USDT입니다.",
    duration: "스테이킹 기간 선택",
    durationInfo: "스테이킹 기간이 길수록 일일 수익률이 높아집니다.",
    Already: "이미 스테이킹됨",
    investta: "투자 계획 선택.",
    wealth: "자산 관리",
    stem: "줄기세포 패키지",
    return: "반환 패키지",
    GIA: "GIA:$2,320",
    FFC: "F-FC:$5,490",
    TCell: "T-Cell:$21,800",
    T10IC: "T10-IC:$23,200",
    ASEL: "ASEL:$32,850",
    ASEL2: "ASEL+:$49,380",
    agreeForm: "간단한 동의서를 검토하세요.",
    returnValue: "반환 값"
  },
  swap: {
    Swap: "스왑",
    paynameA: "결제",
    paynameB: "수령",
    Approve: "승인",
    launchSwap: "스왑 시작",
    recommend: "추천",
    swapError: "스왑 오류",
    swapNetwork: "네트워크 오류",
  },
  menu: {
    stake: "스테이킹",
    burn: "소각",
    Home: "홈",
    Language: "언어",
    Document: "백서",
    Contract: "계약",
    Falcon: "Falcon™",
    Watch: "관찰",    
    Coming: "곧 출시",
    EvDao: "거버넌스",
    Flashloan: "플래시 론 시작",
    Developer: "개발자 문서",
    Launch: "출시",
    xNumber: "남은 수",
    xTips: "자동 처리",
    LogiV2: "Falcon™ V2",
    LogiV3: "Falcon™ V3",
    LogiV4: "Falcon™ V4",
    swap: "스왑",
    LogiV2pro: "Falcon™ V2+",
    LogiV3tips: "Falcon™ V3 글로벌 공개 테스트 시작(일일 요청 1000~7000), 해당 차익 계약의 승인이 필요합니다!",
    Appoove2: "자동",
    Market: "시장",
    Ido: "조기 구독 1라운드",
    myIdo: "내 팔콘",
    interest: "수익",
  },
  Withdraw: {
    Availableassets: "사용 가능한 자산",
    LockedAassets: "잠긴 자산",
    waitwithdraw: "출금 대기 중인 자산",
    Withdraw: "출금",
    Withdrawable: "지갑",
    Earnings: "수익",
    Brokerage: "중개 수수료 금액",
    Reinvest: "재투자",
    Getreward: "보상 받기",
    Processing: "처리 중",
    unsuccessfule: "실패",
    successful: "성공!",
    WithdrawInfo1: "출금 금액을 입력하세요",
    WithdrawInfo2: "수량이 부족합니다",
    burning: "FalconBurn",
    noBurn: "소각 없음",
    addBurn: "소각 수익",
    addBurnCost: "비용",
    BurnCount: "잔액",
    BurnSpeed: "소각 가속",
    needpay: "지불 필요",
    startBurn: "소각 시작",
    Burning: "소각 중",
    noBurn1: "일시 중지됨",
    cangetfalcon: "출금 가능 예상",
    stakedassets: "스테이킹된 자산",
    updateInformation: "정보 업데이트",
    WithdrwaInfo: "잠긴 자산은 제품 또는 분할로 릴리스된 보상에서 유래합니다.",
    WithdrawInfo2: "연소 가속 또는 매핑 중인 원금은 잠긴 상태이며, 출금 서비스 수수료는 2%입니다.",
    WithdrwaInfo3: "잠긴 자산은 매핑 및 고정 예금에서 유래합니다.",
    basicEarnings: "기본 수익",
    ecosystemRewards: "생태계 보상",
    EarningsTitle: "수익 세부 정보",
    Lockedassetsinfo: "이 옵션은 모든 원금을 동결합니다(이익 부분 제외).",
    lockedassets: "잠긴 자산",
    Mapping: "매핑",
    FixedDeposits: "고정 예금",
    Back: "뒤로 가기"
  },
  Team: {
    innver: "내부",
    Teamstaked: "팀 스테이킹",
    leavel: "명예",
    Team: "팀",
    Bind: "관계 바인딩",
    info: "시스템이 일시적으로 바인딩을 중단했습니다. 다시 열릴 때까지 기다려주세요.",
    Binding: "바인딩 진행 중",
    BindingSuccessful: "바인딩 성공",
    BindingFailed: "바인딩 실패",
    Bindinfo: "초대자의 지갑 주소를 입력하세요",
  },
  Share: {
    Invitelink: "초대 링크",
    Intips: `현재 Dapp 초대 기능을 사용할 수 없습니다.
            최신 커뮤니티 업데이트를 기다려주세요.`,
    copyTitle: "초대 링크",
    copyText: "초대 링크가 성공적으로 복사되었습니다!",
    copyCommnad: "링크 복사",
    sharetitle: "공유",
    big: "큰",
    small: "작은",
    Honor: "명예",
    Team: "공유"
  },
  Contract: {
    contractaddress: "계약 주소",
    Copylink: "Polygonscan",
    CopylinkText: "Polygonscan으로 이동!",
  },
  Wathch: {
    watchonlywallet: "읽기 전용 지갑",
    addwallet: "지갑 추가",
    add: "추가",
    Leavel: "레벨",
    share: "공유",
    fnOpen: "이 지갑은 아직 이 기능에 참여하도록 초대되지 않았습니다!",
    nullString: "지갑 주소를 입력하세요!",
    addok: "성공적으로 추가되었습니다",
    addError: "추가 실패",
    addisExists: "이 주소는 이미 존재합니다",
    addlist: "관찰 목록",
  },
  burn: {
    title: "소각",
    Acceieration: "가속",
    Rewards: "보상",
    AlreadyStaked: "이미 스테이킹됨",
    MaximumBurn: "최대 소각",
    BurnEligibility: "소각 자격",
    AddedLPProportion: "추가된 LP 비율",
    noLP: "추가되지 않음",
    yesLP: "추가됨",
    swift: "Swift",
    Start: "가속 시작",
    Burncosts: "소각 비용",
    AlreadyRecovered: "이미 회수됨",
    BurnRewards: "소각 보상",
    FalconEconsysteam: "Falcon 생태계",
    burntime: "소각 비용은 24시간 후에 릴리스됩니다",
    Estimated: "추정됨",
    end: "종료",
    GetReward: "보상 받기",
    SwiftEconsysteam: "Swift 생태계",
    flashloan: "이 기능은 유지보수 중입니다.",
    tips: `선택한 네트워크는 현재 지원되지 않습니다!
    BSC 네트워크를 선택하세요!`,
  },
  liquidity: {
    menu: "유동성",
    title: "유동성 추가",
    ADD: "추가",
    Flashloan: "플래시 론",
    SuperMember: "슈퍼 멤버",
    BalanceSavings: "잔액 저축",
    Rewards: "보상",
    DepositeAmount: "예치 금액",
    RemovingLiquidity: "유동성 제거",
    LPProportion: "LP 비율",
    LPWeightedDividend: "LP 가중 배당금",
    LPtop: "LP 상위",
    or: "또는",
    AddedDuration: "추가된 기간",
    remo: "풀 제거: 현재 100%만 지원됩니다.",
  },
  flashloans: {
    tips: "Swift의 금액을 지정하고 플래시 론을 시작하세요.",
    tips2: "플래시 론 시작에는 Falcon V4 엔진이 사용됩니다.",
    YourBalance: "오늘의 활성화 횟수",
    numer: "일일 활성화 횟수를 선택하세요. 1,000 Falcon 스테이킹 시 하루에 10회 활성화가 가능합니다.",
    AlreadyStaked: "이미 스테이킹됨",
    WalletBalance: "지갑 잔액",
    burn: "소각"
  },
  Governance: {
    lockTips: "원금 잠금: ",
    lockTipsend: "후 자동으로 잠금 해제됨",
    InProgress: "진행 중",
    Congratulations: "축하합니다! 다음을 받았습니다: 라운드",
    QuotaReleasePackage: "쿼터 릴리스 패키지!",
    YourDeposit: "귀하의 예금",
    AlreadyMapped: "이미 매핑됨",
    MappingProportion: "매핑 비율",
    MappingStatus: "매핑 상태",
    Provide: "매핑을 위한 지갑 제공",
    title: "거버넌스",
    StartMapping: "매핑 시작",
    locktime: "매핑이 시작되면 원금은 30일 동안 잠금됩니다(수익은 잠금되지 않음). 반복 매핑 시 잠금 기간이 덮어씌워집니다.",
    tips: "이 계획은 매핑 프로세스를 신속하게 진행하고 최대 용량에 따라 쿼터를 릴리스하기 위해 설계되었습니다."
  },
  crowdfunding: {
    menu: "크라우드펀딩",
    tips: "크라우드펀딩 투자자는 최종적으로 Falcon 재단과 합의된 투자 수익을 받게 됩니다.",
    AlreadyStaked: "이미 스테이킹됨",
    withdrawable: "출금 가능한 수익",
    totalwithdrawn: "총 출금액",
    Authorize: "승인",
    Withdraw: "출금",
    tips2: "크라우드펀딩 계획은 2024년 12월 30일에 종료됩니다.",
  },
  Miner: {
    title: "AI 차익거래 마이너",
  },
  alsteam:{
    "GroupLP": "LP",
    "GroupBurn": "소각",
    "GroupLockALS": "스테이킹",
    "GroupUnLockALS": "언스테이킹",
    "LockWeight":"잠금 가중치"
  }
};
export default ko;

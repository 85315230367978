import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import i18n from "../utils/i18n";
let Factory;
Factory = require("../json/abi/bigmember.json");
// tb合约pg合约方法
export default {
  // 用户信息
  async users(currentAddress, ContractAddress, _decimals) {
    try {
      console.log(currentAddress,'usersusersusers',ContractAddress)

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.users(currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


      newData.investAmount = big(newData.investAmount)
        .div(10 ** 18)
        .toFixed();
      // newData.rewardAmount = big(newData.rewardAmount)
      //   .div(10 ** 18)
      //   .toFixed();
      //   newData.teamInvestAmount = big(newData.teamInvestAmount)
      //     .div(10 ** 18)
      //     .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },
  async tokenUsers(token,currentAddress, ContractAddress, _decimals) {
    try {
      console.log(currentAddress,'usersusersusers',ContractAddress)

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.tokenUsers(token,currentAddress)
      console.log(balance)
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }


      newData.investAmount = big(newData.investAmount)
        .div(10 ** 18)
        .toFixed();
      // newData.rewardAmount = big(newData.rewardAmount)
      //   .div(10 ** 18)
      //   .toFixed();
      //   newData.teamInvestAmount = big(newData.teamInvestAmount)
      //     .div(10 ** 18)
      //     .toFixed();


      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },


  async getMyUserInterest(currentAddress, ContractAddress, _decimals) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyUserInterest(currentAddress)
      console.log(balance)
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },

  async getMyFalconUserInterest(currentAddress, ContractAddress, _decimals) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.getMyFalconUserInterest(currentAddress)
      console.log(balance)
      // ethers转json
      var datas
      datas = big(balance.toString())
        .div(10 ** 18)
        .toFixed();
      return {code: "0", data: datas};
    } catch (error) {
      console.log(error);
    }
  },


  async deposit(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {


      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.deposit(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .deposit(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },

  async depositFalcon(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {
      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.depositFalcon(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .depositFalcon(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },


  async withdraw(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {


      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.withdraw(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .withdraw(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },
  async withdrawFalcon(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {


      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      const amountApproved = big(amountADesired)
        .times(10 ** _decimals)
        .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.withdrawFalcon(amountApproved); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .withdrawFalcon(amountApproved)
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },


  async withdrawInterestAndReward(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {


      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      //   const amountApproved = big(amountADesired)
      //     .times(10 ** _decimals)
      //     .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.withdrawInterestAndReward(); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .withdrawInterestAndReward()
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },

  async withdrawFalconInterestAndReward(tokenAddress, amountADesired, CurrentAccount, _decimals,) {
    try {


      const ethereum = window.ethereum;
      const web3 = new Web3(Web3.givenProvider || ethereum);


      //   const amountApproved = big(amountADesired)
      //     .times(10 ** _decimals)
      //     .toFixed();


      const tokebContract = new web3.eth.Contract(Factory.abi, tokenAddress);

      const approveFunction = tokebContract.methods.withdrawFalconInterestAndReward(); // 替换为您要授权的代币数量

      // gas
      const getGasPrice = await web3.eth.getGasPrice()
      const getGasPriceto = getGasPrice.toString();
      const hexValue = await approveFunction.estimateGas({
        from: CurrentAccount,
        to: tokenAddress,
        data: approveFunction.encodeABI(),
      });


      await tokebContract.methods
        .withdrawFalconInterestAndReward()
        .send({
          from: CurrentAccount,
          //to: tokenAddress,
          data: approveFunction.encodeABI(),
          //gasLimit: hexValue,
          gas: hexValue,
          gasPrice: Math.round(getGasPriceto * 1.01),
        });

      return {code: "0"};
    } catch (error) {
      let errorMessage = "Unknown error";
      let errorData = {};
      let errorCode = "1";
      // 检查 error 对象并提取详细的错误信息
      if (error.message) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("too many arguments")) {
        errorCode = "-1";
        errorMessage = "Node error! Please change the node.";
      }

      if (error.data) {
        errorData = error.data;
        if (error.data.code) {
          errorCode = error.data.code;
        }
      }

      return {
        code: errorCode,
        error: {
          message: errorMessage,
          data: errorData,
        },
      };


    }
  },

};
